.DashboardSection {
  &__paid-message {
    max-width: 400px;
  }

}

.DashboardItems__cell {
  user-select: none;
  display: inline-block;
  vertical-align: center;
  text-align: center;
  width: 60px;
  height: 60px;
  font-size: xx-large;
  border: 1px solid gainsboro;
}

.yum {
  background-color: lightgoldenrodyellow;
}

.fish {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 30px;
}

.TableHeader {
  font-size: small;
  font-weight: bold;
  text-align: center;
  font-variant: small-caps;
}
.TableRow {
  font-family: monospace;
}
